export const excursionDetails = [
    {
      title: "Kandy City Excursion",
      slug: "kandy-city-excursion",
      description:
        "<p>The excursion to the city of Kandy includes a visit to the Sri Dalada Maligawa temple, one of the most sacred temples for Buddhists worldwide, which contains a sacred relic of Gautama Buddha. This relic was gifted to Sri Lanka from India in the 4th century, and the temple in Kandy was built in the early 18th century.</p><p>The famous Kandy Esala Perahera procession departs from this temple.</p><p>The Kandy municipal market is located in a central building where the local population shops for rice, legumes, spices, meat, fish, fruits, and vegetables.</p><p>Kandy Lake is a breath of fresh air for the city and also a place with some urban wildlife such as turtles, large iguanas, and a variety of birds.</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Fkandy.jpg?alt=media&token=7de74979-c057-4355-88c3-30e8b2ffc34f"},
      ]
    },
    {
      title: "Sigiriya Rock Fortress",
      slug: "sigiriya-rock-fortress",
      description:
        "<p>The Sigiriya rock fortress, a UNESCO World Heritage Site, was founded in the 5th century. The city contains high walls, deep moats, gardens, famous paintings, and the lion's entrance to the citadel. It represents the best of ancient Sinhalese engineering, architecture, gardening, hydrology, painting, and sculpture. Additionally, the caves on the slopes of the Sigiriya fortress were used by Buddhist monks 2,200 years ago. Furthermore, there is extensive archaeological evidence of prehistoric settlements in this area.</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Fsigiriya.jpg?alt=media&token=a5ff5251-0bb1-4634-aff0-ebee005874d4"},
        {img:""}
      ],
    },
    {
      title: "Anuradhapura Ancient City",
      slug: "anuradhapura-ancient-city",
      description:
        "<p>The Sacred City of Anuradhapura, a UNESCO World Heritage Site, is one of the largest centers of the ancient Buddhist world and was the first royal capital of the island kingdom, 2,500 years ago. Like the cities of Athens and Rome, it flourished in majesty and grandeur. Sinhalese ambassadors were at the Roman court, especially during the reign of Caesar Augustus.</p><p>Kings, monks, engineers, and sculptors have left the largest collection of Buddhist monuments on the island in this city. Even today, part of the city is considered a Sacred City (puja nagaraya). The most important sites of the ancient city include the Sacred Bodhi Tree (Sri Maha Bodhi), the magnificent temples of Jethavana, Abhayagiri, and Ruvanvaelisaya, the Samadhi Buddha Statue, the Twin Bathing Ponds (Kuttam Pokuna), the Giant Pond (Ath Pokuna), Isurumuni Viharaya, and the Royal Garden (Ranmasu Uyana).</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Fanuradhapura.jpg?alt=media&token=e864c3e6-1dc1-4113-a593-61a5ef597cf2"},
      ],
    },
    {
      title: "Buduruwagala Rock Temple",
      slug: "buduruwagala-rock-temple",
      description:
        "<p>The Buduruwagala rock temple, located amidst the lush vegetation of the Sri Lankan countryside, is a hidden gem of ancient religious art. This remote site is famous for its remarkable rock carvings, which date back to the 9th or 10th century A.D. Marvel at the imposing rock wall adorned with intricately carved figures, including a colossal standing Buddha statue that rises more than 50 feet high. Surrounding the main figure are smaller representations of deities, bodhisattvas, and attendants, each showcasing exquisite craftsmanship and attention to detail. As you explore Buduruwagala, you will be captivated by the serene atmosphere and the sense of reverence that surrounds this sacred site.</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Fbuduruwagala.jpg?alt=media&token=bd4f8d11-8cb5-4b0d-b88f-b43cff8c2420"},
      ],
    },
    {
      title: "Dambulla Cave Temple",
      slug: "dambulla-cave-temple",
      description:
        "<p>The Dambulla cave temple, a UNESCO World Heritage Site, has been used by Buddhist monks since the 2nd century B.C. The first temple was built in the 1st century B.C. by King Walagamba. The site contains five temples built within a very large cave at different times by various Sinhalese kings. Together, they form a magnificent art gallery with statues and colorful paintings.</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Fdambulla.jpg?alt=media&token=d0fabed2-573c-4c02-a502-01715fc9eca8"},
      ],
    },
    {
      title: "Ella Excursion",
      slug: "ella-excursion",
      description:
        "<p>Located in Ella, the Nine Arches Bridge is one of the most iconic bridges in Sri Lanka. It is a stone railway bridge, built by Mr. Appuhami (a local architect) with British engineers during the colonial period. It is surrounded by picturesque views of lush hills. From the 98 Acres viewpoint (Little Sri Padya), visitors can enjoy stunning views of the surroundings and the Ella Gap. Visitors can also enjoy the island's first double zipline, located amidst the lush tea bushes near the base of the viewpoint hill. The dual zipline extends over half a kilometer, sliding at a maximum speed of 80 km/h, and offers a panoramic view of the beautiful hills in the area.</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Fella.jpg?alt=media&token=b99dc92a-aef1-451d-9e0d-1335f7c2ccae"},
      ],
    },
    {
      title: "Fishermen's Boats and Markets",
      slug: "fishermen-s-boats-and-markets",
      description:
        "<p>The boats in the fishing markets sway in the calm waters off the coast of Sri Lanka, their vibrant colors reflected in the morning sunlight. These traditional vessels, adorned with intricate designs and sturdy nets, serve as the livelihood and means of subsistence for coastal communities, providing food and means of living for generations. As the day begins, fishermen bustle around their boats, sorting the day’s catch and preparing for the bustling markets that await them. The air is filled with laughter, conversations, and the occasional call of a seabird overhead. Each boat tells a story of resilience, perseverance, and the timeless connection between man and the sea.</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Ffishermen.jpg?alt=media&token=8d46018c-13f4-4d49-8945-f7e74e4d0e2e"},
      ],
    },
    {
      title: "Galle Fort",
      slug: "galle-fort",
      description:
        "<p>The Galle Fortress was an important port during the reign of the Sinhalese kings, as early as the 12th century B.C. In 1505, a group of Portuguese sailors led by Don Lorenzo de Almeida invaded the island and built the first fortification in Galle on a cliff that jutted into the sea. The fortress was captured by the Dutch on March 13, 1640, after a battle. The small fortification of Galle was considerably expanded and improved by them according to their own architectural style and was considered one of the most important fortresses of the Dutch East India Company (VOC), whose beautifully engraved stone Monogram from 1669 is still visible above the entrance. The Galle Fortress was declared a UNESCO World Heritage Site.</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Fgalle.png?alt=media&token=12dd59b5-f54a-481a-9ca0-7061013a6701"},
      ],
    },
    {
      title: "Dunhinda Waterfalls",
      slug: "dunhinda-waterfalls",
      description:
        "<p>Dunhinda Falls, located near Badulla in Sri Lanka, is one of the most spectacular waterfalls in the country. With a drop of approximately 64 meters, Dunhinda creates a mist that gives it its name, derived from 'dun' in Sinhalese, which means mist or spray. To reach the waterfall, visitors must traverse a picturesque trail through the jungle, adding an element of adventure to the visit. Surrounded by lush vegetation and a tranquil environment, Dunhinda is a popular destination for nature lovers and hikers seeking to enjoy the scenic beauty of Sri Lanka.</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Fdunhinda.jpg?alt=media&token=244c2acd-2b8b-4eda-ab25-1e976985868f"},
      ],
    },
    {
      title: "Mihinthale Ancient Buddhist Monastery",
      slug: "mihinthale-ancient-buddhist-monastery",
      description:
        "<p>The Ancient Monastery of Mihintale is a sacred site in Sri Lanka considered the cradle of Buddhism in the country. This historic place houses a series of religious and ancient structures, including stupas, shrines, and an ancient hospital. The granite staircase with 1,840 steps leads to the top of the mountain, where the great stupa of Mahaseya is located. From here, one can enjoy panoramic views of the surrounding landscape. Mihintale is an important destination for Buddhist pilgrims and a cultural gem for visitors interested in the history and spirituality of Sri Lanka.</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Fmihintale.jpg?alt=media&token=8a5c172d-237f-43c3-b34f-75ff15e27d26"},
      ],
    },
    {
      title: "Minneriya National Park Jeep Safari",
      slug: "minneriya-national-park-jeep-safari",
      description:
        "<p>A jeep safari in Minneriya National Park offers the chance to observe a wide variety of wildlife in its natural habitat. Famous for the 'Elephant Gathering,' where hundreds of elephants congregate around the Minneriya tank during the dry season, the park also houses leopards, deer, water buffalo, and a variety of exotic birds. During the safari, visitors can enjoy stunning landscapes that include grasslands, forests, and wetlands, all from the comfort and safety of a guided jeep.</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Fminneriya.jpg?alt=media&token=50322ef9-e1cc-4158-8e39-b313d68d66b0"},
      ],
    },
    {
      title: "Nelligala Rock Temple",
      slug: "nelligala-rock-temple",
      description:
        "<p>The Nelligala Temple, located in the hills of Kandy, Sri Lanka, is renowned for its impressive Buddha statue, one of the largest in the country at approximately 25 meters in height. In addition to the statue, the temple features beautiful gardens and meditation areas to enjoy the tranquility of the surroundings. It is a sacred place for local Buddhists and a popular destination for tourists seeking culture and spirituality. It offers a fascinating visit that combines art, religion, and nature in a serene and peaceful setting.</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Fnelligala.jpg?alt=media&token=cc81a077-4aa0-40d0-848a-e44170067478"},
      ],
    },
    {
      title: "Nuwara Eliya City Excursion",
      slug: "nuwara-eliya-city-excursion",
      description:
        "<p>Nuwara Eliya is a tourist city in the central highlands of Sri Lanka, blessed with a healthy climate and stunning views of valleys, meadows, mountains, and tea plantations. Nuwara Eliya, also known as 'Little England,' was the favorite retreat of British colonists who tried to recreate a typical English village. The Grand Hotel, the red-brick post office, the Hill Club, the 18-hole golf course, Victoria Park, and Gregory Lake are the most notable tourist attractions.</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Fnuwaraeliya.jpg?alt=media&token=4e38dd82-0b53-42ee-b95f-18c5a2d6d216"},
      ],
    },
    {
      title: "Polonnaruwa Ancient City",
      slug: "polonnaruwa-ancient-city",
      description:
        "<p>Polonnaruwa, a UNESCO World Heritage Site, was the medieval capital of Sri Lanka between the 10th and 12th centuries A.D. Polonnaruwa also served as a strategically important city during the Anuradhapura era. Within the citadel (Inner City) are the Royal Palace of King Parakumbhahu the Great, the Council Chamber, and the Sacred Quadrangle with the Temple of the Sacred Relic (Watadage), the Image House (Thuparama), the Hatadage, the Stone Inscription Tablet (Galpotha), and the Seven-Story Temple (Sath Mahal Prasadaya).</p><p>Other important sites in the ancient city include the Lankathilaka and Thiwanka image chapels, the large and well-preserved temples of Rankoth Vehera and Kirivehera, the Gal Viharaya rock temple containing exquisitely carved Buddha statues, the monastic and library complex (Pothgul Viharaya), and the reservoir of King Parakrama (Parakrama Samudraya). Travelers with Guia Lanka Tours have the option to explore the ancient city of Polonnaruwa by bicycle.</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Fpolonnaruwa.jpg?alt=media&token=4e268aa0-b548-4262-9190-e2fd84ff4540"},
      ],
    },
    {
      title: "Ramboda Waterfalls",
      slug: "ramboda-waterfalls",
      description:
        "<p>amboda Falls, located in the central hills of Sri Lanka, is an impressive waterfall known for its picturesque beauty. Cascading from a height of over 90 meters, it offers a tranquil escape amidst lush vegetation. Visitors can admire the falls from viewpoints along the way or get closer to experience its refreshing mist.</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Framboda.jpg?alt=media&token=11d5e1ad-15a0-4f99-a249-78c3bebb66cc"},
      ],
    },
    {
      title: "Ravana Waterfalls",
      slug: "ravana-waterfalls",
      description:
        "<p>Ravana Falls, near the town of Ella in Sri Lanka, is an impressive natural attraction with a height of approximately 25 meters. According to local legend, it is associated with the Hindu epic Ramayana. It offers a stunning visual spectacle and is a must-see for those exploring the Ella region. It is a perfect place to relax and enjoy the beauty of nature.</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Fravana.jpg?alt=media&token=8968d57d-8eca-411f-8ff1-1774463e77fc"},
      ],
    },
    
  ];
  