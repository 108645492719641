import React from "react";
import "./styles.css";
import { Container, Row, Col } from "react-bootstrap";
const AboutSection = () => {
  return (
    <>
      <section className="mainSection aboutSection" id="about">
        <Container className="bg-light-green2">
          <Row>
            <Col md={4} className='d-none d-lg-block'>
                <div className="aboutImageContainer padding-over-50 padding-left-50">
                    <img src={'https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fpages%2Fhome%2Fabout1.jpg?alt=media&token=3cda77f4-a4ca-4ae4-9641-3c0acbd57a8d'} alt="GET TO KNOW GUIA LANKA TOURS ABOUT US" />
                </div>
            </Col>
            <Col md={8} className="padding-side-50 padding-over-50 content-align-center">
              <h2 className="h2 h2-main text-left">
                {" "}
                <span className="small">Get to Know Guia Lanka Tours</span>
                <br />
                About Us
              </h2>
              <p>
              A truly authentic travel experience builds upon a personal, human connection with the people or the place visiting. A moment of heartfelt shared experience. As the traveler acts in a caring, compassionate and responsible manner with the host community, he/she can experience the sense of wonder about the people and the places visited as a positive emotion.
              </p>

            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AboutSection;
