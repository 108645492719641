import React from 'react';
import { Route, Routes } from "react-router-dom";
import HomePage from './HomePage/HomePage';
import Tours from './Tours/Tours';
import Offers from './Offers/Offers';
import ExcursionsView from './Excursions/ExcursionsView';
import Excursions from './Excursions/Excursions';
// import Cursor from '../common/Cursor/Cursor';

const Page = () => {

    return(
        <>
            {/* <Cursor /> */}
            <Routes>
            <Route path="/" element={<HomePage/>}/>
                <Route path="/:element" element={<HomePage/>}/>
                <Route path="/tours/:id" element={<Tours />} />
                <Route path="/excursions" element={<Excursions />} />
                <Route path="/excursions/:id" element={<ExcursionsView />} />
                <Route path="/offers/:id" element={<Offers />} />
            </Routes>
            
        </>
    );

}

export default Page;