import React from "react";
import HomeTourCard from "../../../common/HomeTourCard/HomeTourCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Container,Row, Col } from "react-bootstrap";
import './styles.css';

const TourSection = ({ tours }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 465 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  var items = [];
  items = tours;


  return (
    <>
      <section className="mainSection toursSection bg-dark-green" id="tours">
        <Container >
            <Row>
                <Col md={12}>
                    <h2 className="h2 h2-main color-white text-center padding-bottom-50"><span className="small">Discover Our</span><br/>Tours</h2>
                </Col>
                <Col md={12} className="top-padding-150">
                <Carousel
          swipeable={false}
          draggable={false}
          showDots={false}
          responsive={responsive}
          ssr={false} // means to render carousel on server-side.
          infinite={true}
          autoPlay={false}
          autoPlaySpeed={1000}
          keyBoardControl={false}
          customTransition="all .5s"
          transitionDuration={500}
          containerClass="carousel-container"
        >
            {items ?
                items.map((item, i) => {
                   return(
                    <HomeTourCard key={i} data={item}/>
                   )
                }) : ""}
          
        </Carousel>
                </Col>
            </Row>
        </Container>
        

        
      </section>
    </>
  );
};

export default TourSection;
