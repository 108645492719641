import React from "react";
import "./styles.css";
import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SingleTourCard from "../../../common/SingleTourCard/SingleTourCard";

const SingleTour = ({ title, slides, description }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 465 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <>
      <section className="mainSection singleTourSection">
        <Container fluid>
          <Row>
            <Col md={9} className="padding-side-0">
              <Carousel
                swipeable={false}
                draggable={false}
                showDots={false}
                responsive={responsive}
                ssr={false} // means to render carousel on server-side.
                infinite={true}
                autoPlay={false}
                autoPlaySpeed={1000}
                keyBoardControl={false}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
              >
                {slides ? slides.map((slide, i) => {
                    return(
                        <SingleTourCard imgSrc={slide.img} key={i}/>
                    )
                }) : "" }

                
              </Carousel>
            </Col>

            <Col
              md={3}
              className="padding-side-50 padding-over-50 content-align-center"
            >
              <h1 className="h1 h1-main text-left color-green">{title}</h1>
            </Col>
            
          </Row>
          <Container>
          <Row>
            <Col md={4}>
                <h2 className="h2 h2-main color-green">Description</h2>
            </Col>
          <Col md={8} className='padding-over-50'>
          <span
                                dangerouslySetInnerHTML={{ __html: description }}
                              ></span>
          </Col>
          </Row>
          
          </Container>
        </Container>
      </section>
    </>
  );
};

export default SingleTour;
