import React, { useEffect } from 'react';
import VideoSection from './components/VideoSection';
import TourSection from './components/ToursSection';
import AboutSection from './components/AboutSection';
import TravelPartnerSection from './components/TravelPartnerSection';
import {toursDetails} from '../../../constants/toursConstant';
import ContactSection from './components/ContactSection';
import { useParams } from 'react-router-dom';
const HomePage = () => {
    let {element} =  useParams();
    
    useEffect(() => {
        const handleScroll = () => {
            const el = document.getElementById(element);
            if(element)
            {
                // console.log("element", element);
                el.scrollIntoView({ behavior: 'smooth' });
            }
        }
        handleScroll();
    },[element]);
    return(
        <>
        <VideoSection url={'https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/homeVideo%2Fweb-2023.mp4?alt=media&token=71ba2fc8-27d9-4595-8016-3712970cd6c9'}/>

        <TourSection tours={toursDetails} />

        <AboutSection />

        <TravelPartnerSection />

        <ContactSection/>
        </>
    );
}

export default HomePage;