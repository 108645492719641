import React from 'react';
import { Container, Row, Col, } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './styles.css';
import {TfiFacebook} from 'react-icons/tfi';
import {GrInstagram} from 'react-icons/gr';
import {FiYoutube} from 'react-icons/fi';
import {SiTripadvisor} from 'react-icons/si';
import {AiOutlineWhatsApp} from 'react-icons/ai';

const ContactSection = () => {

    return(
        <>
        <section className='mainSection contactSection bg-dark-green color-white'id="contact">
            <Container className='padding-over-50'>
                <Row>
                    <Col md={6}>
                    <nav className="ContactNavCont">
          
          <ul className="mainNav">
            <li>
              <Link to={"/"} >Home</Link>
            </li>
            <li>
              <Link to={"/#tours"} >Tours</Link>
            </li>
            <li>
              <Link to={"/#about"} >About Us</Link>
            </li>
            <li>
              <a href={"https://indochinanaturally.com"}>
                Indochina Naturally
              </a>
            </li>
          </ul>
        </nav>
                    </Col>
                    <Col md={6}>
                    <nav className="ContactNavCont">
          
          <ul className="mainNav floatList">
            <li>
              <a href={"https://www.facebook.com/guialankatours"}  rel="noreferrer" target="_blank"><TfiFacebook size={20} /></a>
            </li>
            <li>
              <a href={"https://www.instagram.com/guia_lanka_tours/"}  rel="noreferrer" target="_blank"><GrInstagram size={20}/></a>
            </li>
            <li>
              <a href={"https://www.youtube.com/channel/UCTLR34DuQulMccHTV0h4NRw"}  rel="noreferrer" target="_blank"><FiYoutube size={30} /></a>
            </li>
            <li>
              <a href={"https://www.tripadvisor.com/Attraction_Review-g304138-d12989298-Reviews-Guia_Lanka_Tours-Kandy_Kandy_District_Central_Province.html"}  rel="noreferrer" target="_blank">
                <SiTripadvisor size={30} />
              </a>
            </li>
            <li>
                <a href='https://wa.me/+947707395004' rel="noreferrer" target="_blank">
                    <AiOutlineWhatsApp size={30} />
                </a>
            </li>
            
            <div className='clearfix'></div>
            <li>
                <a href="mailto:tours@guialankatours.com">tours@guialankatours.com</a>
            </li>

            <div className='clearfix'></div>
            <li>
                <a href="tel:+94770739500">+94770739500</a>
            </li>
          </ul>
        </nav>
                    </Col>
                </Row>
                <Row>
                    <Col className='color-white text-center'>
                        <span>Copyright © {(new Date().getFullYear())} GUIA LANKA TOURS</span>
                    </Col>
                </Row>
            </Container>
        </section>
        </>
    );
}

export default ContactSection;