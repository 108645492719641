import React from "react";
import "./styles.css";
import { Container, Row, Col } from "react-bootstrap";
const TravelPartnerSection = () => {
  return (
    <>
      <section className="mainSection travelPartnerSection" id="travelPartner">
        <Container fluid>
          <Row>
            <Col className="d-flex justify-content-center mt-5 gap-3">
              <span className="tripadvisor-cc">
                
                <img alt=""
                  className="tripadvisor-cc-img"
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Ftripadvisor%2Ftripadvisor-2022.png?alt=media&token=454a6afb-b4bc-4671-912f-c8f1bf3f5c54"} />
              </span>
              <span className="tripadvisor-cc">
                
                <img alt=""
                  className="tripadvisor-cc-img"
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Ftripadvisor%2Ftripadvisor-2023.png?alt=media&token=4b2b8e29-cce9-4f05-975b-a67450d96d27"} />
              </span>
              <span className="tripadvisor-cc">
                
                <img alt=""
                  className="tripadvisor-cc-img"
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Ftripadvisor%2Ftripadvisor-2024.png?alt=media&token=f707d6f3-a18c-4166-aa91-d1a81d354f64"} />
              </span>
            </Col>
          </Row>
          <Row>
            <Col
              md={4}
              className="content-align-center padding-over-50 padding-side-50 text-right"
            >
              <h2 className="h2 h2-main color-green">
                <span className="small">Visit Our Travel Partner</span>
                <br />
                <a href={"https://indochinanaturally.com"}>
                  Indochina Naturally
                </a>
              </h2>
            </Col>
            <Col md={8}>
              <div className="aboutImageContainer padding-over-50 padding-right-50">
                <img
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fpages%2Fhome%2Fguiatoindochina.png?alt=media&token=6fc2f38c-adf9-4b65-8fd9-b38580ca2e31"
                  }
                  alt="GET TO KNOW GUIA LANKA TOURS ABOUT US"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default TravelPartnerSection;
